import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "item" }
const _hoisted_2 = ["target", "href"]
const _hoisted_3 = ["target", "href"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_NotesTable = _resolveComponent("NotesTable")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_DataGridDrilldown = _resolveComponent("DataGridDrilldown")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    ref: "dataGrid",
    data: _ctx.gridData,
    "expand-row-keys": _ctx.expandRowKeys,
    "summary-method": _ctx.getSummaries,
    border: "",
    "max-height": "800",
    "row-key": "id",
    "show-overflow-tooltip": "",
    "show-summary": "",
    stripe: "",
    onSortChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeSort($event))),
    "default-sort": _ctx.sortOrder,
    onRowDblclick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-drawer', $event))),
    onCellClick: _ctx.editNote,
    onExpandChange: _ctx.toggleDrilldown,
    onSelectionChange: _ctx.handleSelectionChange,
    class: "table-striped",
    "table-layout": "auto"
  }, {
    empty: _withCtx(() => [
      _createVNode(_component_el_empty, { description: "No Data" })
    ]),
    default: _withCtx(() => [
      (_ctx.userSelection)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            type: "selection"
          }))
        : _createCommentVNode("", true),
      (_ctx.hasNotes)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            class: "el-text__center",
            width: "40"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_popover, {
                placement: "right",
                trigger: "click",
                width: "800px"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "text",
                    onClick: ($event: any) => (_ctx.getNotes(scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        class: _normalizeClass(`el-text__${scope.row.notes ? 'primary' : 'input'}`),
                        icon: "sticky-note"
                      }, null, 8, ["class"])
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_NotesTable)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.drillThrough)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 2,
            class: "el-text__center",
            width: "40"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                type: "text",
                onClick: ($event: any) => (
            _ctx.$router.push({
              name: 'ViewDashboard',
              params: { id: _ctx.$route.params.id },
              query: {
                gridName: _ctx.gridName,
                dataRow: `${_ctx.params(scope.row).toString()}`,
                elementsHeight: _ctx.elements ? _ctx.elements[0].scrollTop : 0,
                mainHeight: _ctx.main ? _ctx.main[0].scrollTop : 0,
                page: _ctx.gridRequest.page_index,
                size: _ctx.gridRequest.page_size,
              },
            })
          )
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "external-link-alt" })
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.drilldowns && _ctx.drilldowns.length > 0)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 3,
            type: "expand"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataGridDrilldown, {
                drilldown: _ctx.drilldowns[0],
                "drilldown-data-row": _ctx.dataRow,
                "drilldown-next-level": _ctx.drilldowns[1]
              }, {
                default: _withCtx(() => [
                  (_ctx.drilldowns[1])
                    ? (_openBlock(), _createBlock(_component_DataGridDrilldown, {
                        key: 0,
                        drilldown: _ctx.drilldowns[1],
                        "drilldown-data-row": _ctx.dataRow,
                        "drilldown-next-level": _ctx.drilldowns[2]
                      }, null, 8, ["drilldown", "drilldown-data-row", "drilldown-next-level"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["drilldown", "drilldown-data-row", "drilldown-next-level"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: index,
          "class-name": `el-text__${column.column_align}`,
          label: column.header_text,
          prop: column.id,
          sortable: "custom",
          "sort-orders": ['ascending', 'descending'],
          width: column.width
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(column.header_text) + " ", 1),
            _createVNode(_component_el_popover, {
              width: "250",
              class: "el-text__white",
              placement: "bottom",
              trigger: "click",
              onHide: _ctx.clearFilter
            }, {
              reference: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: _normalizeClass(
                `el-text__${_ctx.columnFilters(column.id).length > 0 ? 'primary' : 'white'}`
              ),
                  type: "text",
                  onClick: ($event: any) => (_ctx.setActiveColumn(column.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      class: _normalizeClass(
                  `el-text__${_ctx.columnFilters(column.id).length > 0 ? 'primary' : 'white'}`
                ),
                      icon: "filter"
                    }, null, 8, ["class"])
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  inline: true,
                  onSubmit: _withModifiers(($event: any) => (_ctx.onSearch(column.id, column.type)), ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_autocomplete, {
                          id: `searchInput-${column.id}`,
                          ref_for: true,
                          ref: "searchInput",
                          modelValue: _ctx.searchValue,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
                          "fetch-suggestions": _ctx.queryColumnData,
                          "popper-append-to-body": false,
                          placeholder: "Search ...",
                          autofocus: "",
                          onSelect: ($event: any) => (_ctx.onSearch(column.id, column.type))
                        }, {
                          default: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_1, [
                              (column.type === 'date')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createTextVNode(_toDisplayString(item.label
                          ? _ctx.moment.utc(item.label).format("MM-DD-YYYY")
                          : ''), 1)
                                  ], 64))
                                : (column.type === 'money')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(item.label ?
                          new Intl.NumberFormat(
                            'en-US', { style: 'currency', currency: 'USD' }
                          ).format(item.label)
                          :
                          ''), 1)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ], 64))
                            ])
                          ]),
                          _: 2
                        }, 1032, ["id", "modelValue", "fetch-suggestions", "onSelect"])
                      ]),
                      _: 2
                    }, 1024),
                    _withDirectives(_createVNode(_component_el_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          icon: "el-icon-close",
                          type: "text",
                          onClick: _ctx.clearFilter
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    }, 512), [
                      [_vShow, _ctx.columnResponse.rows && _ctx.columnResponse.rows.length > 0]
                    ])
                  ]),
                  _: 2
                }, 1032, ["onSubmit"]),
                _createVNode(_component_el_scrollbar, { "max-height": "250px" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnFilters(column.id), (filter, filterIndex) => {
                          return (_openBlock(), _createBlock(_component_el_col, {
                            key: filterIndex,
                            lg: 24,
                            md: 24,
                            sm: 24,
                            xl: 24,
                            xs: 24
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: "el-icon-close",
                                type: "text",
                                onClick: ($event: any) => (_ctx.removeFilter(filter))
                              }, {
                                default: _withCtx(() => [
                                  (column.type === 'date')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(_toDisplayString(filter.filter_text
                        ? _ctx.moment.utc(filter.filter_text).format("MM-DD-YYYY")
                        : ''), 1)
                                      ], 64))
                                    : (column.type === 'money')
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createTextVNode(_toDisplayString(filter.filter_text ?
                        new Intl.NumberFormat(
                          'en-US', { style: 'currency', currency: 'USD' }
                        ).format(Number(filter.filter_text))
                        :
                        ''), 1)
                                        ], 64))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                          _createTextVNode(_toDisplayString(filter.filter_text), 1)
                                        ], 64))
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onHide"]),
            (_ctx.columnFilters(column.id).length > 0)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  icon: "el-icon-close",
                  type: "text",
                  onClick: ($event: any) => (_ctx.resetColumnFilters(column.id))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _withDirectives(_createVNode(_component_el_button, {
              class: "el-text__white",
              icon: "el-icon-caret-top",
              type: "text"
            }, null, 512), [
              [_vShow, _ctx.sortOrder.order === 'ascending' && _ctx.sortOrder.prop === column.id]
            ]),
            _withDirectives(_createVNode(_component_el_button, {
              class: "el-text__white",
              icon: "el-icon-caret-bottom",
              type: "text"
            }, null, 512), [
              [_vShow, _ctx.sortOrder.order === 'descending' && _ctx.sortOrder.prop === column.id]
            ])
          ]),
          default: _withCtx((scope) => [
            (column.extended_properties)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (
              column.extended_properties.hyperlink && column.extended_properties.resource)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (column.extended_properties.tooltip)
                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                              key: 0,
                              placement: "right",
                              enterable: false
                            }, {
                              content: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("a", {
                                  target: column.extended_properties.hyperlink.target,
                                  href: scope.row[column.extended_properties.hyperlink.ref_field]
                                }, [
                                  _createVNode(_component_el_image, {
                                    src: 
                    `/images/${scope.row[column.extended_properties.resource.ref_field]}`
                  ,
                                    alt: "",
                                    style: {"width":"28px","height":"28px"},
                                    fill: "fill"
                                  }, null, 8, ["src"])
                                ], 8, _hoisted_2)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createElementVNode("a", {
                          target: column.extended_properties.hyperlink.target,
                          href: scope.row[column.extended_properties.hyperlink.ref_field]
                        }, [
                          _createVNode(_component_el_image, {
                            src: 
                  `/images/${scope.row[column.extended_properties.resource.ref_field]}`
                ,
                            alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                            style: {"width":"28px","height":"28px"},
                            fill: "fill"
                          }, null, 8, ["src", "alt"])
                        ], 8, _hoisted_3)
                      ], 64))
                    : (column.extended_properties.hyperlink)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (column.extended_properties.tooltip)
                            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                key: 0,
                                placement: "right",
                                enterable: false
                              }, {
                                content: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("a", {
                                    onClick: ($event: any) => (_ctx.redirectToSingleView(
                  column.id === 'invoice_no' ? scope.row['invoice_id'] : scope.row[column.id],
                  column,
                ))
                                  }, _toDisplayString(scope.row[column.id]), 9, _hoisted_4)
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                onClick: ($event: any) => (_ctx.redirectToSingleView(
                column.id === 'invoice_no' ? scope.row['invoice_id'] : scope.row[column.id],
                column,
              ))
                              }, _toDisplayString(scope.row[column.id]), 9, _hoisted_5))
                        ], 64))
                      : (column.extended_properties.resource)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (column.extended_properties.tooltip)
                              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                  key: 0,
                                  placement: "right",
                                  enterable: false
                                }, {
                                  content: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(scope.row[column.extended_properties.tooltip.ref_field]), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_image, {
                                      src: 
                  `/images/status/${scope.row[column.extended_properties.resource.ref_field]}`
                ,
                                      alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                                      fill: "fill"
                                    }, null, 8, ["src", "alt"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_el_image, {
                                  key: 1,
                                  src: 
                `/images/status/${scope.row[column.extended_properties.resource.ref_field]}`
              ,
                                  alt: `${scope.row[column.extended_properties.resource.ref_field]}`,
                                  fill: "fill"
                                }, null, 8, ["src", "alt"]))
                          ], 64))
                        : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (column.type === 'date')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(scope.row[column.id]
                ? _ctx.moment.utc(scope.row[column.id]).format("MM-DD-YYYY")
                : ''), 1)
                      ], 64))
                    : (column.type === 'money')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(scope.row[column.id] ?
                new Intl.NumberFormat(
                  'en-US', { style: 'currency', currency: 'USD' }
                ).format(scope.row[column.id])
                :
                ''), 1)
                        ], 64))
                      : (column.id === 'customer_name' && _ctx.$route.params.id === 'customers')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            href: `/customers/${scope.row['external_id']}`,
                            target: "_blank"
                          }, _toDisplayString(scope.row[column.id]), 9, _hoisted_6))
                        : (column.id === 'notes')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              (_ctx.isEditingNote && _ctx.activeNoteRow.id === scope.row.id)
                                ? (_openBlock(), _createBlock(_component_el_form, {
                                    key: 0,
                                    onSubmit: _withModifiers(_ctx.saveNote, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_input, {
                                            modelValue: _ctx.note,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.note) = $event)),
                                            type: "textarea",
                                            onBlur: ($event: any) => (_ctx.saveNote(scope.row[column.id]))
                                          }, null, 8, ["modelValue", "onBlur"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onSubmit"]))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(scope.row.notes), 1)
                                  ], 64))
                            ], 64))
                          : (column.id.startsWith('alert'))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                (scope.row[column.id] && scope.row[column.id] > 0)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_font_awesome_icon, {
                                        class: "el-text__danger",
                                        icon: "exclamation-triangle"
                                      }),
                                      _createVNode(_component_el_badge, {
                                        value: scope.row[column.id],
                                        class: "el-text__danger el-text__small",
                                        type: "danger"
                                      }, null, 8, ["value"])
                                    ], 64))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                _createTextVNode(_toDisplayString(column.prefix && scope.row[column.id] ? column.prefix : '') + " " + _toDisplayString(scope.row[column.id]), 1)
                              ], 64))
                ], 64))
          ]),
          _: 2
        }, 1032, ["class-name", "label", "prop", "width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data", "expand-row-keys", "summary-method", "default-sort", "onCellClick", "onExpandChange", "onSelectionChange"]))
}